import ReactDOM from 'react-dom';

import { useState } from 'react';

const Link = props => (
  <button
    className="underline text-blue-600"
    onClick={props.onClick}
    aria-label={`Open ${props.label}`}
  >
    {props.text}
  </button>
);

const Modal = props => {
  const [show, setShow] = useState(false);

  if (typeof window === 'undefined') return null;

  return (
    <>
      {show &&
        ReactDOM.createPortal(
          <>
            <div className="fixed inset-4 md:inset-8 flex justify-center items-center overflow-x-hidden overflow-y-auto z-100 outline-none focus:outline-none">
              <div className="absolute md:mx-auto h-full w-full md:max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  {props.title && (
                    <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                      <h3 className="text-3xl font-semibold">{props.title}</h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShow(false)}
                        aria-label="close"
                      >
                        <span className="bg-transparent text-gray-600 hover:text-gray-800 opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                  )}
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <div className="my-4 text-gray-600 text-lg leading-relaxed">
                      {props.content}
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                    <button
                      className="text-red-100 bg-red-500 rounded-md font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                      style={{ transition: 'all .15s ease' }}
                      onClick={() => setShow(false)}
                      aria-label="close"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>,
          document.getElementById('modal-root')
        )}
      {props.link && (
        <Link
          label={props.title}
          text={props.link}
          onClick={() => setShow(true)}
        />
      )}
      {props.button &&
        React.cloneElement(props.button, { onClick: () => setShow(true) })}
    </>
  );
};

Modal.defaultProps = {
  button: null,
  content: null,
  link: null,
  submit: null,
  title: null
};

export default Modal;
