import { useTabStore } from 'utils/store';

import Footer from 'components/Footer';
import Logo from 'components/Logo';
import TabMenu from 'components/TabMenu';

const Desktop = ({ content, header }) => (
  <div className="hidden md:flex h-full w-sidebar flex-col shadow-2xl bg-white z-20 divide-gray-200 divide-y">
    <header className="px-4 py-5">
      <Logo />
    </header>
    <main className="flex flex-col relative overflow-y-auto divide-gray-200 divide-y h-full">
      <div className="grid row-auto p-4">{header}</div>
      <div className="pb-4 flex-1">{content}</div>
      <Footer />
    </main>
  </div>
);

const Mobile = ({ content, header }) => {
  const isDetails = useTabStore(state => state.isDetailsTab());
  const isMap = useTabStore(state => state.isMapTab());
  const isInfo = useTabStore(state => state.isInfoTab());
  return (
    <>
      <div className="md:hidden absolute w-full flex flex-col z-20 divide-gray-200 divide-y">
        {isDetails && <DetailsTab header={header} content={content} />}
        {isMap && <MapTab header={header} content={content} />}
        {isInfo && <InfoTab header={header} content={content} />}
      </div>
      <TabMenu />
    </>
  );
};

/**
 * Map tab for mobile layout. Note: mobile map omits program details.
 */
const MapTab = props => (
  <div className="mx-2 mt-2 sm:mx-3 sm:mt-3 shadow-2xl bg-white">
    <header className="px-2 py-3">
      <Logo />
    </header>
    <nav className="grid row-auto">{props.header}</nav>
    <main className="">
      {props.content && React.cloneElement(props.content, { minimal: true })}
    </main>
  </div>
);

const DetailsTab = props => (
  <div className="fixed bottom-16 h-full-16 w-full pb-12 shadow-2xl bg-white divide-gray-200 divide-y">
    <header className="px-4 py-3">
      <Logo />
    </header>
    <div className="overflow-y-auto h-full w-full divide-gray-200 divide-y">
      <nav className="grid row-auto gap-2 p-4">{props.header}</nav>
      <main className="">{props.content}</main>
    </div>
  </div>
);

const InfoTab = props => {
  return (
    <div className="fixed bottom-16 h-full-16 w-full pb-20 shadow-2xl bg-white divide-gray-200 divide-y">
      <header className="px-4 py-3">
        <Logo />
        <div className="hidden md:block absolute top-0 right-0 z-10 m-4 mt-4"></div>
      </header>
      <main className="overflow-y-auto h-full w-full divide-gray-200 divide-y text-gray-900">
        <div className="p-4">
          Medmap is an interactive map of medical residency and fellowship
          program locations for the NRMP Match. The site is designed to help
          medical students and MD physicians locate training programs, including
          internships, residencies, and fellowships, within the United States
          and U.S. territories.
        </div>
        <div className="absolute bottom-0 w-full">
          <Footer />
        </div>
      </main>
    </div>
  );
};

const Sidebar = props => (
  <>
    <Desktop {...props} />
    <Mobile {...props} />
  </>
);

export default Sidebar;
