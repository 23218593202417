import Link from 'next/link';

const logo = require('public/img/logo.png?size=160');

const Logo = () => (
  <div className="w-full flex justify-between items-center">
    <Link href="/">
      <a className="flex items-center outline-none" tabIndex="0">
        <img className="h-8 w-auto" src={logo.src} alt="medmap.io" />
        <span className="font-logo ml-1 -mt-1 text-2xl text-blue-800">
          medmap.io
        </span>
      </a>
    </Link>
  </div>
);

export default Logo;
