function Map(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        className="prefix__heroicon-ui"
        d="M14 5.62l-4 2v10.76l4-2V5.62zm2 0v10.76l4 2V7.62l-4-2zm-8 2l-4-2v10.76l4 2V7.62zm7 10.5L9.45 20.9a1 1 0 01-.9 0l-6-3A1 1 0 012 17V4a1 1 0 011.45-.9L9 5.89l5.55-2.77a1 1 0 01.9 0l6 3A1 1 0 0122 7v13a1 1 0 01-1.45.89L15 18.12z"
      />
    </svg>
  );
}

const MemoMap = React.memo(Map);
export default MemoMap;
