import dynamic from 'next/dynamic';

import Modal from 'components/Modal';

const ContactForm = dynamic(() => import('components/Contact/Form'));
const Privacy = dynamic(() => import('./Privacy'));
const Terms = dynamic(() => import('./Terms'));

const Footer = () => {
  return (
    <footer className="w-full p-4 text-gray-700 text-sm block">
      <div className="inline-grid grid-flow-col gap-4">
        <div className="">© Motua LLC, 2016-{new Date().getFullYear()}.</div>
        <Modal content={<ContactForm />} link="Contact" title="Contact Us" />
        <Modal content={<Privacy />} link="Privacy" title="Privacy Policy" />
        <Modal content={<Terms />} link="Terms" title="Terms of Service" />
      </div>
    </footer>
  );
};

export default Footer;
