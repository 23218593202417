function Info(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        className="prefix__heroicon-ui"
        d="M12 22a10 10 0 110-20 10 10 0 010 20zm0-2a8 8 0 100-16 8 8 0 000 16zm0-9a1 1 0 011 1v4a1 1 0 01-2 0v-4a1 1 0 011-1zm0-4a1 1 0 110 2 1 1 0 010-2z"
      />
    </svg>
  );
}

const MemoInfo = React.memo(Info);
export default MemoInfo;
