function File(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path
        className="prefix__heroicon-ui"
        d="M6 2h9a1 1 0 01.7.3l4 4a1 1 0 01.3.7v13a2 2 0 01-2 2H6a2 2 0 01-2-2V4c0-1.1.9-2 2-2zm9 2.41V7h2.59L15 4.41zM18 9h-3a2 2 0 01-2-2V4H6v16h12V9zm-2 7a1 1 0 01-1 1H9a1 1 0 010-2h6a1 1 0 011 1zm0-4a1 1 0 01-1 1H9a1 1 0 010-2h6a1 1 0 011 1zm-5-4a1 1 0 01-1 1H9a1 1 0 110-2h1a1 1 0 011 1z"
      />
    </svg>
  );
}

const MemoFile = React.memo(File);
export default MemoFile;
