import cn from 'classnames';

import { useTabStore } from 'utils/store';

import Icon from 'components/Icon';

const Tab = ({ icon: IconComponent, label, selected, onClick }) => (
  <li
    className={cn(
      'w-1/3 flex flex-col justify-center items-center h-full cursor-pointer px-10 pt-1 border-b-4',
      {
        'border-teal-400': selected,
        'border-white': !selected
      }
    )}
    onClick={onClick}
  >
    <IconComponent
      className={cn('fill-current', {
        'text-teal-500': selected,
        'text-gray-800': !selected
      })}
    />
    <span
      className={cn('text-xs mt-1', {
        'text-gray-900 font-medium': selected,
        'text-gray-800': !selected
      })}
    >
      {label}
    </span>
  </li>
);

const TabMenu = () => {
  const isDetails = useTabStore(state => state.isDetailsTab());
  const isInfo = useTabStore(state => state.isInfoTab());
  const isMap = useTabStore(state => state.isMapTab());

  const setDetails = useTabStore(state => state.setDetailsTab);
  const setInfo = useTabStore(state => state.setInfoTab);
  const setMap = useTabStore(state => state.setMapTab);

  return (
    <div className="md:hidden fixed inset-x-0 bottom-0 0 w-screen bg-white h-16 z-20 border-t border-gray-200">
      <ul className="flex justify-around items-center h-full text-sm">
        <Tab
          label="Info"
          selected={isDetails}
          icon={Icon.File}
          onClick={setDetails}
        />
        <Tab label="Map" selected={isMap} icon={Icon.Map} onClick={setMap} />
        <Tab
          label="About"
          selected={isInfo}
          icon={Icon.Info}
          onClick={setInfo}
        />
      </ul>
    </div>
  );
};

export default TabMenu;
